<template>
  <CRow>
    <CCol col="12" lg="8">
      <CCard style="max-width: 40rem;">
        <CCardHeader>
          Delete Remediation Item <strong> {{ item.item_title }}</strong>
        </CCardHeader>
        <CCardBody>
            <CCardBody v-show="loading">          
                  <p class="text-center">
                    <CSpinner />
                  </p>                  
            </CCardBody>
  
            <CCardBody class="p-1" v-show="!loading">
              <span> <h6> Remediation Item  <strong>{{ item.item_title }}  </strong> is linked to the following items: </h6></span>
              <CRow class="align-items-center mt-3">
                <CCol col="12" xl class="mb-3 mb-xl-0" v-if="itemReports.length > 0">
                  <h5>Reports (click to open)</h5>
                  <CListGroup>
                    <CListGroupItem v-for="report in itemReports" :key="report.report_id" target="_blank" :href="'/#/reports/'+report.report_id" >
                        {{ report.report_name || report.report_id }} ( <strong> Status: </strong>{{ report.report_status }})
                    </CListGroupItem>
                  </CListGroup>
                </CCol>
                <CCol col="12" xl class="mb-3 mb-xl-0" v-else>
                  <CListGroup>
                    <CListGroupItem>
                      <strong>None</strong>
                    </CListGroupItem>
                  </CListGroup>
                  
                </CCol>
              </CRow>
              <CRow form class="form-group">

                <CCol sm="12">
                  <CInputCheckbox
                    label="Force deletion"
                    name="Force deletion"
                    :checked="forceDeleteBoxChecked"
                    key="forceDeletion"                    
                    @update:checked=forceDeleteChecked
                    :disabled=forceDeleteBoxDisabled
                  />
                </CCol>
                <CCol sm="12" class="pt-3">
                  <CAlert v-show=forceDeleteBoxChecked color="danger">
                    <h4 class="alert-heading">Warning</h4>
                    All reports will loose their reference and need to be adjusted manually!
                  </CAlert>
                </CCol>


              </CRow>

            </CCardBody>          
        </CCardBody>
        <CCardFooter>
              <CButton color="light" class="float-left col-sm-2" @click="goBack">Back</CButton>  
              <CButton  @click="deleteModal = true" color="danger" class="float-right col-sm-2" :disabled="deleteDisabled" >Delete</CButton>                         
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
      title="Delete Remediation Item"
      color="danger"
      :show.sync="deleteModal"
    >
      <CAlert v-show=forceDeleteBoxChecked color="danger">
        <strong>Warning:</strong> All reports will loose their reference and need to be adjusted manually!      
      </CAlert>
      <p>Do you really want to delete the remediation item <strong> {{ item.item_title }}</strong>?</p>
      <p>This <strong>can't</strong> be undone!</p>
      
      <div slot="footer" class="w-100">
          <CButton v-on:click="deleteItem();" color="danger" class="ml-1 mr-1 float-right col-sm-2" >Delete</CButton>  
          <CButton @click="deleteModal = false"  color="light" class="ml-1 mr-1 float-right col-sm-2" >Cancle</CButton>  
      </div>
    </CModal>    
  </CRow>
</template>


<script>
import ItemService from '@/services/item.services';
import ReportService from '@/services/report.services';


export default {
  data() {
    return {
      item: {},
      itemReports: [],
      deleteDisabled: true,
      forceDeleteBoxChecked: false,
      forceDeleteBoxDisabled: false,
      deleteModal: false,
      loading: true

    };
  },

  methods: {
    deleteItem() {
      ItemService.deleteItem(this.$route.params.id).then(
        response => { },
        error => {   
          console.log(error)   
        }      
      )
      
      this.$router.push({path: '/remediation-items'});
    },
    forceDeleteChecked(value, event) {
      this.forceDeleteBoxChecked = value;
      this.deleteDisabled = !value;
    },
    goBack() {
      this.$router.push({path: '/remediation-items'})
    }
  },
  created() { },
  mounted() {
    ItemService.getItem(this.$route.params.id).then(
      response => { 
        this.item = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    ReportService.getItemReports(this.$route.params.id).then(
      response => { 
        this.itemReports = response;
        if(this.itemReports.length < 1) {
          this.deleteDisabled = false
          this.forceDeleteBoxDisabled = true
        }
        this.loading = false
      },
      error => {   
        console.log(error)   
      }         
    )
  },

};

</script>
